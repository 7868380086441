<template>
  <div>
    <div v-if="showCreate" class="d-flex align-items-center justify-content-end px-2">
      <ion-button class="button" @click="createWorld"> Create world </ion-button>
    </div>
    <div v-if="worldListing">
      <world-card-small
        v-for="world of worldListing"
        :key="world.id"
        :world="world"
        class="my-3"
        :current-tab="currentTab"
        :member-count="get(memberCounts, `${world.id}.member_count`) || 0"
        :world-unread-count="get(worldUnreadCounts, `${world.id}.worldunread_msgs_count`) || 0"
      />
      <p v-show="nextPageExists" class="clickable" @click="requestLoadMore">Load More</p>
      <p v-show="!nextPageExists" class="end-of-list">End of list</p>
    </div>
    <div v-else class="no-data">No worlds yet</div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 's-vuex-class';
import WorldCardSmall from '@/shared/components/WorldCardSmall.vue';
import namespace from '@/shared/store/namespace';

import { WorldsInfo } from '@/shared/types/static-types';
import { getWorldsMemberCounts, getWorldsUnreadCounts } from '@/shared/actions/worlds';
import { authStore } from '@/shared/pinia-store/auth';
import store from '@/shared/store';

@Options({
  name: 'InfiniteCharactersHome',
  components: {
    WorldCardSmall,
  },
})
export default class InfiniteCharacters extends Vue {
  @Prop() public currentTab!: string;
  @Prop() public draft!: any;

  public get showCreate() {
    return this.currentTab === 'myworlds';
  }

  public createWorld() {
    const router = useRouter();
    const href = router.resolve({ name: 'create-story-world' }).href;
    location.assign(href);
  }

  public areMemberCountsLoading = false;
  public areWorldUnreadCountsLoading = false;
  public memberCounts = {};
  public worldUnreadCounts = {};
  get = get;

  public get worldListing() {
    if (this.currentTab === 'trending') return this.trendingWorlds;
    else if (this.currentTab === 'top') return store.getters['WorldsModule/topWorlds'];
    else if (this.currentTab === 'latest') return store.getters['WorldsModule/latestWorlds'];
    else if (this.currentTab === 'drafts') return this.draft;
    else return this.userWorlds;
  }

  @Watch('worldListing')
  worldListingChanged() {
    if (get(this.worldListing, 'length') && this.isAuthenticated) {
      this.fetchWorldsMemberCounts();
      this.fetchWorldUnreadCounts();
    }
  }

  @Getter('trendingWorlds', { namespace: namespace.WorldsModule })
  public trendingWorlds!: WorldsInfo[];

  @Getter('userWorlds', { namespace: namespace.WorldsModule })
  public userWorlds!: WorldsInfo[];

  @Getter('userWorldsPaging', { namespace: namespace.WorldsModule })
  public userWorldsPaging!: any;

  @Getter('trendingWorldsPaging', { namespace: namespace.WorldsModule })
  public trendingWorldsPaging!: any;

  @Action('trendingWorldsNextPage', { namespace: namespace.WorldsModule })
  public trendingWorldsNextPage!: any;

  @Getter('latestWorldsPaging', { namespace: namespace.WorldsModule })
  public latestWorldsPaging!: any;

  @Getter('topWorldsPaging', { namespace: namespace.WorldsModule })
  public topWorldsPaging!: any;

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  @Action('latesttWorldsNextPage', { namespace: namespace.WorldsModule })
  public latesttWorldsNextPage!: any;

  @Action('userWorldsNextPage', { namespace: namespace.WorldsModule })
  public userWorldsNextPage!: any;

  @Action('topWorldsNextPage', { namespace: namespace.WorldsModule })
  public topWorldsNextPage!: any;

  public get nextPageExists() {
    if (this.currentTab === 'trending') return !!this.trendingWorldsPaging.next;
    else if (this.currentTab === 'top') return !!this.topWorldsPaging.next;
    else if (this.currentTab == 'latest') return !!this.latestWorldsPaging.next;
    else return !!this.userWorldsPaging.next;
  }

  public async requestLoadMore(ev: CustomEvent) {
    if (this.currentTab === 'trending') {
      if (!this.trendingWorldsPaging.next) {
        (ev?.target as any).complete();
      } else {
        await this.trendingWorldsNextPage();
      }
    } else if (this.currentTab === 'top') {
      if (!this.topWorldsPaging.next) {
        (ev?.target as any).complete();
      } else {
        await this.topWorldsNextPage();
      }
    } else if (this.currentTab === 'latest') {
      if (!this.latestWorldsPaging.next) {
        (ev?.target as any).complete();
      } else {
        await this.latesttWorldsNextPage();
      }
    } else if (!this.userWorldsPaging.next) {
      (ev?.target as any).complete();
    } else {
      await this.userWorldsNextPage();
    }
  }

  public async fetchWorldsMemberCounts() {
    this.areMemberCountsLoading = true;
    this.memberCounts = {};
    const resp = await getWorldsMemberCounts(map(this.worldListing, 'id'));
    this.memberCounts = keyBy(resp, 'world_id');
    this.areMemberCountsLoading = false;
  }

  public async fetchWorldUnreadCounts() {
    this.areWorldUnreadCountsLoading = true;
    this.worldUnreadCounts = {};
    const resp = await getWorldsUnreadCounts(map(this.worldListing, 'id'));
    this.worldUnreadCounts = keyBy(resp, 'world_id');
    this.areWorldUnreadCountsLoading = false;
  }
}
</script>

<style scoped lang="sass">
.button
  text-transform: none !important
  --border-radius: 6px !important
</style>
