<template>
  <ion-page class="page bg-transparent">
    <div class="title">Story Worlds</div>

    <worlds-tabs class="my-2" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />
    <infinite-worlds class="my-2" :current-tab="currentTab" :draft="worlddrafts" />
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Action, Getter } from 's-vuex-class';
import TopBanner from '../home/components/TopBanner.vue';
import WorldsTabs from './components/WorldsTabs.vue';
import InfiniteWorlds from './components/InfiniteWorlds.vue';
import { worldsTabs } from '@/shared/statics/tabs';
import namespace from '@/shared/store/namespace';
import { Paging, Tab } from '@/shared/types/static-types';
import { getUserStoryWorld, getWorldDrafts } from '@/shared/actions/worlds';
import { authStore } from '@/shared/pinia-store/auth';


@Options({
  name: 'WorldsPage',
  components: {
    WorldsTabs,
    InfiniteWorlds,
    TopBanner,
  },
})
export default class WorldsPage extends Vue {
  public tabs = worldsTabs;
  public currentTab: string = 'trending';
  public tabIndex = 0;
  public allListings: any[] = [];
  public isLoading = true;
  public paging: Paging | null = null;

  @Action('getTrendingWorlds', { namespace: namespace.WorldsModule })
  public getTrendingWorlds!: any;

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  @Action('getTopWorlds', { namespace: namespace.WorldsModule })
  public getTopWorlds!: any;

  @Action('getLatestWorlds', { namespace: namespace.WorldsModule })
  public getLatestWorlds!: any;

  @Action('getUserWorlds', { namespace: namespace.WorldsModule })
  public getUserWorlds!: any;

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  worlddrafts: any = [];
  public get userId() {
    return this.user.id;
  }

  public tabChanged({ value }: Tab) {
    const router = useRouter();
    this.currentTab = value;
    router.replace({ name: 'worlds-tabs', query: { tab: value } });
  }

  public async userWorld() {
    await getUserStoryWorld(this.userId, 'story', 1);
  }

  public async fetchWorldDrafts() {
    const resp = await getWorldDrafts('story', 1);
    this.worlddrafts = resp.results;
  }

  public async created() {
    const document = useDocument();
    const router = useRouter();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

    this.getTopWorlds({});
    this.getLatestWorlds({});
    this.getTrendingWorlds({});
    this.getUserWorlds({ user: this.userId, type: 'story', page: 1 });
    this.fetchWorldDrafts();

    const tIndex = indexOf(
      this.tabs,
      find(this.tabs, (tab) => tab.value === router.currentRoute.value.query.tab)
    );
    if (router.currentRoute.value.query.tab && tIndex !== -1) {
      this.tabIndex = tIndex;
      this.currentTab = router.currentRoute.value.query.tab as string;
    }
  }
}
</script>
<style lang="sass" scoped>
.infinite-home
  --background: transparent
  height: calc(100% - 58px - 40px)
  overflow: auto
  &::-webkit-scrollbar
    display: none

.name
  font-size: 18px
  font-weight: bold
  color: #737373
  cursor: pointer
  .name:hover
    opacity: 0.7

.new-world-btn
  color: #214163
  font-size: 14px
  font-weight: bold
.dark .new-world-btn
  color: #fff
.title
  margin-left: 0.5rem
  font-size: 24px
  font-weight: bold
</style>
